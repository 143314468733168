import { css } from 'astroturf';
import React from 'react';

import { ArrowRight } from 'react-bytesize-icons';
import Platforms from '../../components/Platforms';
import Link from '../../components/link';

const styles = css`
  .platforms {
    position: relative;
    padding: 4rem 2rem 16rem;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    display: block;
    max-width: 82rem;

    & > div {
      display: flex;
      gap: 3rem;
      justify-content: center;
    }

    & > svg {
      height: 64px;
      width: 300px;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;

      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-position: 50% 50%;
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;
      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & > div::before {
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      top: -100px;
      width: 100%;
      max-width: 90vw;
      height: 500px;
      border-radius: 4rem;
      padding: 2rem 0;
      opacity: 0.5;

      mask-image: radial-gradient(ellipse at center, #000 40%, transparent 95%);
      background-image: radial-gradient(#208dc8 1px, transparent 0);
      background-size: 40px 40px;
      background-position: -19px -19px;
    }
  }

  .primaryButton {
    width: fit-content;
    position: relative;
    pointer-events: all;
    margin: 0;
    font-size: calc(16px + 0.25vw);
    font-weight: 900;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    background-image: linear-gradient(135deg, oklab(0.61 -0.08 -0.21), oklab(0.66 -0.24 0.16));
    border-radius: 40px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    padding: 0;
    margin-top: 1.5rem;
    border-top: 2px solid #0a153088;
    border-bottom: 1px solid #0a1530dd;
    @media (forced-colors: active) {
      border: 1px solid;
    }

    & span {
      position: relative;
      z-index: 1;
      padding: 0.33rem 1rem;
      content: ' ';
      display: block;

      width: calc(100%);
      height: calc(100%);
      z-index: 0;
      opacity: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 40px;
      background: #0a1530;
      overflow: hidden;
    }

    & svg {
      vertical-align: middle;
      margin-left: 10px;
    }

    @media (min-width: 600px) {
      & span {
        font-size: 1.125rem;
      }
    }

    &:hocus {
      text-decoration: none;

      border-top-color: #aff9;
      & span {
        background: transparent;
      }
    }
  }
`;

function Brands(props) {
  return (
    <div className={[styles.platforms].join(' ')}>
      <Platforms color="#d8ecf8" />
      <h2>Windows, macOS and Linux</h2>
      <p>Install Polypane on your favorite operating system (and architecture).</p>

      <div>
        <Link className={styles.primaryButton} to="https://dashboard.polypane.app/register/">
          <span>
            Try Polypane for free <ArrowRight width={16} height={16} strokeWidth="4px" />
          </span>
        </Link>
        <Link className={styles.primaryButton} to="/docs/portal/">
          <span>
            Portal documentation{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
              <path d="M12 21c-3.314 0 -6 -2.462 -6 -5.5s2.686 -5.5 6 -5.5" />
              <path d="M21 12c0 3.314 -2.462 6 -5.5 6s-5.5 -2.686 -5.5 -6" />
              <path d="M12 14c3.314 0 6 -2.462 6 -5.5s-2.686 -5.5 -6 -5.5" />
              <path d="M14 12c0 -3.314 -2.462 -6 -5.5 -6s-5.5 2.686 -5.5 6" />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Brands;
